// extracted by mini-css-extract-plugin
export var ArtistDescription = "KeithHaring-module--ArtistDescription--NQgr3";
export var ArtistInfos = "KeithHaring-module--ArtistInfos--6zHvf";
export var ButtonWrapper = "KeithHaring-module--ButtonWrapper --c7AK+";
export var CardWrapper = "KeithHaring-module--CardWrapper--An1tt";
export var CarrouselWrapper2 = "KeithHaring-module--CarrouselWrapper2--GaozS";
export var Citations = "KeithHaring-module--Citations--fXhso";
export var DescriptionWrapper = "KeithHaring-module--DescriptionWrapper--uqdmX";
export var ImageWrapper = "KeithHaring-module--ImageWrapper--vSPXd";
export var LinkWrapper = "KeithHaring-module--LinkWrapper--nwK9H";
export var MobileProtrait = "KeithHaring-module--MobileProtrait--rGODg";
export var More = "KeithHaring-module--More--lXoGk";
export var MoreButton = "KeithHaring-module--MoreButton--WazGy";
export var NameWrapper = "KeithHaring-module--NameWrapper--AFLwO";
export var PdpWrapper = "KeithHaring-module--PdpWrapper--WRwav";
export var PhotosWrapper = "KeithHaring-module--PhotosWrapper--TIIiR";
export var ProfilWrapper = "KeithHaring-module--ProfilWrapper--tTu4q";
export var TitleWrapper = "KeithHaring-module--TitleWrapper--gsmvT";
export var Wrapper = "KeithHaring-module--Wrapper--o-cUk";