import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./KeithHaring.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import KeithHaringPhoto1 from "../../../../res/Photos site/Keith Haring/Keith Haring - studio.jpeg"
import KeithHaringPhoto2 from "../../../../res/Photos site/Keith Haring/Keith Haring 3.jpeg"
import KeithHaringPhoto3 from "../../../../res/Photos site/Keith Haring/Keith Haring painting.jpeg"
import KeithHaringPhoto4 from "../../../../res/Photos site/Keith Haring/keith-haring-jean-michel-basquiat-andy-warhol-taschen-numero-magazine.webp"
import KeithHaringVideo1 from "../../../../res/Photos site/Keith Haring/VIDEO-2022-02-04-11-48-42.mp4"
import PhotoduMoment3 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Keith Haring - Untitled (kangaroo).jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/haring/portrait.jpg'
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "KeithHaring",
  name: "KeithHaring",
  description:
  "Born on May 4, 1958 in Reading, Pennsylvania, Keith Haring is an artist and draftsman who is considered a major figure in contemporary art. Attracted from childhood by art and drawing, Keith Haring entered the Ivy School of Professional Art in Pittsburgh at the age of 18. Soon bored with advertising design, he left the school at the end of the first year and enrolled in the School of Visual Arts in New York. Immersed in the intellectual and artistic ferment of New York in the 1980s, Keith Haring used the city as a medium for his creativity. Inspired by the graffiti that covered the city's walls, he chose the unused billboards in the corridors of the New York subway to create his drawings and make them visible to the greatest number of people. Haring's works are characterized by a repetition of synthetic forms surrounded by black and often filled with colors as hot as they are bright. Their graphics are relatively simple and uncluttered, but they give the impression that the characters in these works are in motion thanks to lines borrowed from the comic strip. Whatever the medium, the continuous line remains the inseparable element of his style. Keith Haring was quickly recognized in the pop art world and his first exhibitions met with great success, such as at the Fun Gallery at the Sidney Janis Gallery in 1983. The artist is present in major institutional collections such as the Museum of Contemporary Art in Los Angeles and San Diego, the MoMa, the Museum of Modern and Contemporary Art in New York or the Louvre Abu Dhabi for example.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de KeithHaring.",
  photos: [
    { src: KeithHaringPhoto1, name: "KeithHaring" },
    { src: KeithHaringPhoto2, name: "KeithHaring" },
    { src: KeithHaringPhoto4, name: "KeithHaring" },
  ],
  citations:[]
};


const infos2 = {
  photos: [
    { src: PhotoduMoment3, name: "Keith Haring", artist: "Keith Haring (1958 - 1990)", title:"Untitled Kangaroo, 1984", desc1:"Encre sur papier", desc2:"Ink on paper", desc3:"121 x 85,8 cm", desc4:"48x33 3⁄4 in.", desc5:"132,5 x 97 cm (encadré)", desc6:"52,16 x 38.19 in. (framed)", desc10:"<h4>Certificat d'authenticité de l'Estate Keith Haring New York 12 octobre 1990, n° 101290A6.\n<i>Certificate of authenticity of the Estate Keith Haring New York October 12 1990, n° 101290A6.</i></h4>", desc7:"- Collection particulière.\n- Collection particulière, Allemagne.\n- Collection Lio Malca, États-Unis.,", desc11:"<h4><i>- Keith Haring visits Australia</i>, documentaire réaliséé en 1984 où il a collaboré avec National Gallery of Victoria and the New South Wales Art Gallery</h4>"  },
  ],
};

const KeithHaring = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>KEITH HARING <h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >1958-1990</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p style={{fontWeight: "bold"}}>1978</p>
          <p>- School of Visual Arts, New York</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Keith Haring, Palazzo Blu, Piza, Italy.</p>
          <p>- Keith Haring: Radiant Vision, Naples Art Association, Naples, Florida, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Andy Wharol & Keith Haring, Gormleys Fine Arts Dublin, Dublin, Ireland.</p>
          <p>- Keith Haring: Grace House Mural, Museum of Contemporary Art, Denver, Colorado, USA.</p>
          <p>- Keith Haring: Radiant Vision, Fenimore art Museum, Cooperstown, New York, USA.</p>
          <p>- Keith Haring Art in Action, Opéra Gallery, Miami, Florida, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Keith Haring, Museum Folkwang, Essen, Germany</p>
          <p>- Keith Haring, BOZAR, Brussels, Belgium</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Keith Haring, Tate Liverpool, UK</p>
          <p>- Keith Haring, Gladstone Gallery, Brussels, Belgium</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Keith Haring : Art is Life, Life is Art, Dongdaemun Design Plaza, Seoul, South Korea</p>
          <p>- Apocalypse, Pace Prints, New York, NY, US</p>
          <p>- Drawing Social Impact, Nakamura Keith Haring Collection, Kobuchizawa, Japan</p>
          <p>- Keith Haring : the Chicago Mural, Chicago Cultural Center, Chicago, IL, US</p>
          <p>- Keith Haring : The Alphabet, Albertina, Vienna, Austria 2017</p>
          <p>- Keith Haring : the End of the Line, Cranbrook Art Museum, Bloomfield Hills, MI, US</p>
          <p>- Keith Haring : Posters, Museum für Kunst und Gewerbe, Hamburg, Germany</p>
          <p>- Keith Haring : About Art, Palazzo Reale Milan, Italy</p>
          <p>- Keith Haring and Japan : Pop to Neo-Japonism, Nakamura Keith Haring Collection, Kobuchizawa, Japan</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Post-PoP : Prints of Keith Haring, Middlebury College Museum of Art, Middlebury, VT, US</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Keith Haring : Multiplexism, National Museum of History, Taipei, Taiwan</p>
          <p>- Keith Haring : The Political Line, Kunsthal Rotterdam, The Netherlands</p>
          <p>- Keith Haring : Multiplexism, Nakamura Keith Haring Collection, Kobuchizawa, Japan</p>
          <p>- Keith Haring : Prints and Other Works from Nakamura Keith Haring Collection, Sagawa Museum, Shiga, Japan</p>
          <p>- Keith Haring, Pace Prints, New York, NY, US</p>
          <p>- Heaven and Hell, Skarstedt Gallery, New York, NY, US</p>
          <p>- Keith Haring : The Political Line, Kunsthalle der Hypo-Kulturstiftung, Munich, Germany</p>
          <p>- Out of the Vaults : Keith Haring, Haggerty Museum of Art , Marquette University, Milwaukee, WI, US</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Keith Haring, Gladstone Gallery, New York, NY</p>
          <p>- Keith Haring : The political Line, de Young, San Francisco, CA</p>
          <p>- Keith Haring, Laurent Strouk Gallery, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Keith Haring : Langages, Fales Library, New York University, NY</p>
          <p>- Keith Haring and the Pop World : Retrospect, Nakamura Museum, Yamanashi, Japan</p>
          <p>- Keith Haring : The Political Line, Musée d’Art Moderne de la Ville de Paris, Paris, France</p>
          <p>- Keith Haring : The Political Line / Grands Formats, Le CENTQUATRE, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Keith Haring, Gladstone Gallery, Brussels, Belgium</p>
          <p>- Murale de Milwaukee, Sala dei Porti, nel percorso di visita degli Appartamenti settecenteschi di Palazzo Reale, Caserta, Italy</p>
          <p>- Murale di Milwaukee, Museo Archeologico Nazionale d’Abruzzo, Abruzzo, Italy</p>
          <p>- Love Pop!, Itami City Museum of Art, Itami Cirt, Hyogo, Japan</p>
          <p>- Keith Haring : 1978 - 1982, The Brooklyn Museum, Brooklyn, New York, United States</p>
          <p>- Keith Haring : Posters, Lehigh University Galleries, Rauch Business Center Gallery, Bethlehem, Pennsylvania</p>
          <p>- Keith Haring, Galeria d’Art del Centro de Extensión de la Pontificia Universidad Católica de Chile, Santiago, Chile</p>
          <p>- Keith Haring Blueprints, Pace Prints Chelsea, New York, NY, United States</p>
          <p>- Keith Haring Extra Large, Chiesa di San Francesco, Udine, Italy</p>
          <p>- Pisa Mural Restoration Commemoration, Pisa, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Keith Haring 1978 - 1982, Contemporary Arts Center, Cincinnati, Ohio, United States</p>
          <p>- The Hieroglyphics of Keith Haring, Musee en Herbe, Paris, France</p>
          <p>- Keith Haring, Gladstone Gallery, New York, NY, United States</p>
          <p>- Keith Haring, Pace Prints, New York, NY, United States</p>
          <p>- Keith Haring : Subway Drawings, Arcadia University, Glenside, Pennsylvania, United States </p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Keith Haring : A New Dimension, Grounds for Sculpture, Hamilton, New Jersey, United States</p>
          <p>- Keith Haring 1978 - 1982, Kunsthale Wien, Wienna, Austria</p>
          <p>- The Keith Haring Show, SOMA Museum of Arts, Seoul, South Korea</p>
          <p>- Keith Haring : 20th Anniversary, Tony Shafrazi Gallery, New York, United States</p>
          <p>- Keith Haring, Van de Weghe Fine Art, New York, NY, United States</p>
          <p>- Keith Haring Editions on Paper, Caixa Cultural, Sao Paulo, Brazil</p>
          <p>- Keith Haring Editions on Paper, Caixa Cultural, Rio de Janeiro, Brazil</p>
          <p>- Keith Haring : Pop Art Superstar, Lotte Art Center, Busan, South Korea</p>
          <p>- Keith Haring : Pop Art Superstar, Sangrok Museum Gwangju Museum of Art, Gwangju, South Korea</p>
          <p>- One Person Show, Institut d’Art Contemporain in Villeurbanne, Villeurbanne, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Keith Haring, Bentley Gallery, Scottsdale, Arizona, United States</p>
          <p>- Keith Haring, Vecchiato Gallery, Milan, Italy</p>
          <p>- Keith Haring, Kunsthalle Weishaupt, Ulm, Germany</p>
          <p>- The Keith Haring Show, Musee des Beaux-Arts, Mons, Belgium</p>
          <p>- Keith Haring : Print Retrospective 1982 - 1990, Colins C. Dibolle Art Gallery of Loyola University, New Orleans, Louisiana, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Keith Haring Retrospective, Museum of Contemporary Art, Lyon, France</p>
          <p>- One Person Show, Ludwigmuseum, Budapest, Hungary</p>
          <p>- Keith Haring - Drawings, Paintings and Sculptures, Skarstedt Gallery, New York, NY, United States</p>
          <p>- Keith Haring Houston Street & Bowery Mural Re-creation, Deitch Projects, New York, NY, United States</p>
          <p>- The Ten Commandments, Deitch Projects, Long Island City, Queens, New York, United States</p>
          <p>- Against All Odds : Keith Haring in the Rubell Family Collection, Palm Springs Art Museum, Palm Springs, California, United States</p>
          <p>- Keith Haring : Two Sculptures, UNAIDS, Geneva, Switzerland</p>
          <p>- Keith Haring, Galerie Laurent Strouk, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Complete Editions on Paper, Fundacion Galicia, Ferrol, Spain</p>
          <p>- Keith Haring : The Milwaukee Mural, Serrone della Villa Reale, Monza, Italy</p>
          <p>- Keith Haring Life as a Drawing, Ludwigmuseum, Koblenz, Germany</p>
          <p>- Keith Haring : Works from the Navarra Collection, Galerie de Independence and Parc Heintz Fondation, Dexia, Luxembourg</p>
          <p>- Keith Haring : Editions on Paper, Egon Schiele Centrum, Cesky Krumlov, Czech Republic</p>
          <p>- Keith Haring - Untitled (Acrobats), 1986, Katonah Museum of Art, South Lawn, Katonah, NY, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Keith Haring : Journey of the Radiant Baby, Reading Public Museum, Reading, Pennsylvania, United States</p>
          <p>- Editions on Paper, University of Valencia, Valencia, Spain</p>
          <p>- Keith Haring : Art and Commerce, Tampa Museum of Art, Tampa, Florida, United States</p>
          <p>- Keith Haring : Paintings, Sculpture, Objects and Drawings, Tony Shafrazi Gallery, New York, NY, United States</p>
          <p>- Against All Odds, Rubell Family Collection, Miami, Florida, United States</p>
          <p>- Keith Haring : POP Haring - Symbols and Icons Editions on paper from the Estate of Keith Haring, Singapore Tyler Print Institute, Singapore</p>
          <p>- Complete Editions on Paper, Centro Cultural Montehermoso Depsito de Aguas, Vitoria, Spain</p>
          <p>- Complete Editions on Paper, Fundacion Caixa Galicia, Ferrol, Spain</p>
          <p>- Keith Haring, Monumental Sculptures, Poppy & Pierre Salinger Foundation, Le Thor, France</p>
          <p>- Keith Haring Drawings, Alona Kagan Gallery, New York, NY, United States</p>
          <p>- Keith Haring - Early Drawings, Baltic Centre for Contemporary Art, Gateshead, United Kingdom</p>
          <p>- Keith Haring, Galerie Jérôme de Noirmont, Paris, France</p>
          <p>- Keith Haring Prints, Memo-art Galeria, Budapest, Hungary</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Keith Haring Sculptures, Deitch Projects, New York, NY, United States</p>
          <p>- Keith Haring, Alpen-Adria Galerie, Klagenfurt, Austria</p>
          <p>- Keith Haring, Ben Brown Fine Arts, London, England</p>
          <p>- One Person Show, Galleria Salvatore Ala, Milan, Italy</p>
          <p>- Keith Haring : Urbain Memory, Fundacion ICO, Madrid, Spain</p>
          <p>- Five Keith Haring Sculptures, Somerset House, London, England</p>
          <p>- Three Keith Haring Sculptures, Levers House, New York, NY, United States</p>
          <p>- The Keith Haring Show, La Triennale di Milano, Milan, Italy</p>
          <p>- L’Art a la Plage #4: Keith Haring, Galerie Enrico Navarra in cooperation with Galerie Jérôme de Noirmont, St Tropez, France</p>
          <p>- Editions on Paper, Fundacion Canal, Madrid, Spain</p>
          <p>- Keith Haring : Coloring Book Drawings, Briggs-Robinson Gallery, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Keith Haring, Centro Cultural Banco do Brasil, Rio de Janeiro, Brazil</p>
          <p>- Keith Haring, Culturgest, Lisbon, Portugal</p>
          <p>- Keith Haring, Alte Rathaus, Prien am Chiemsee, Germany</p>
          <p>- The Characters of Keith Haring, Children’s Museum of the Arts, New York, NY, United States</p>
          <p>- Photos from the Archives : the Artist at Work, Pop Shop, New York, NY, United States</p>
          <p>- Keith Haring : New Wave Aztec, Solomon R. Guggenheim Museum, New York, NY, United States</p>
          <p>- One Person Show, Alona Kagan Gallery, New York, NY, United States </p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Keith Haring : Short Message. Posters 1982 - 1990, Museum für Kunst und Gewerbe, Hamburg, Germany</p>
          <p>- Keith Haring, Kunstmuseum, Heidenheim, Germany</p>
          <p>- Keith Haring, Kunsthaus, Kaufbeuren , Germany</p>
          <p>- Pop Figuration, Deitch Projects, New York, NY, United States</p>
          <p>- Four Sculptures, American Academy, Rome, Italy</p>
          <p>- Haring Drawings, Culturgest, Porto, Portugal</p>
          <p>- One Person Show, Kagan-Martos Gallery, New York, NY, United States</p>
          <p>- Keith Haring, Centro Cultural Banco do Brasil, São Paulo, Brazil</p>
          <p>- Keith Haring, Centro Cultural Banco do Brasil, Brasilia, Brazil</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- One Person Show, Arario Gallery, Seoul, Korea</p>
          <p>- Keith Haring : Heaven and Hell, Museum Boijmans van Beuningen, Rotterdam, Holland</p>
          <p>- Keith Haring : Tarps, Van de Weghe Gallery, New York, NY, United States</p>
          <p>- Sex Show, Galerie Jérôme de Noirmont, Paris, France</p>
          <p>- Keith Haring : Short Message. Posters 1982 - 1990, Versicherungskammer Bayern, Munich, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Keith Haring Sculptures, Rome, Italy</p>
          <p>- The 10 Commandments, Wapping Power Station, London, England</p>
          <p>- One Person Show, Castello Ursino, Catania, Sicily, Italy</p>
          <p>- Keith Haring : Heaven and Hell, Museum fur Neue Kunst/ZKM, Karlsruhe, Germany</p>
          <p>- One Person Show, National Gallery of the Cayman Islands, Georgetown, Grand Cayman</p>
          <p>- Paradise Garage, Deitch Projects, New York, NY, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Keith Haring : the SVA Years, School of Visual Arts, New York, NY, United States</p>
          <p>- Keith Haring, Isetan Museum, Tokyo, Japan</p>
          <p>- Keith Haring, Sapporo City Museum, Niigata, Japan</p>
          <p>- Keith Haring, Amos Anderson Museum, Helsinki, Finland</p>
          <p>- Keith Haring : Sculptures on the Kurfurstendamm, Berlin Ludwig Forum, Aachen, Germany</p>
          <p>- One Person Show, Chiostro del Bramante, Rome, Italy</p>
          <p>- Keith Haring Sculptures, Rome, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Keith Haring, Eki Museum, Kyoto, Japan</p>
          <p>- Keith Haring, Kurashiki City Museum, Kurashiki, Japan</p>
          <p>- Keith Haring, Iwaki City Museum, Iwaki, Japan</p>
          <p>- Keith Haring, Isetan Museum, Tokyo, Japan</p>
          <p>- Keith Haring Sculptures, Pacific Design Center, Los Angeles, California, United States</p>
          <p>- Keith Haring Sculptures, Museum of Science and Industry, Chicago, Illinois, United States</p>
          <p>- Keith Haring: A Retrospective, City Gallery, Wellington, New Zealand</p>
          <p>- One Person Show, Galerie Jérôme de Noirmont, Paris, France</p>
          <p>- Keith Haring, Musee Maillol, Paris, France</p>
          <p>- One Person Show, Katonah Art Museum, Katonah, New York, United States</p>
          <p>- One Person Show, Peter Gwyther Gallery, London, England</p>
          <p>- One Person Show, Casino Knokke, Knokke, Belgium</p>
          <p>- One Person Show, Museum of Contemporary Art, Scottsdale, Arizona, United States</p>
          <p>- One Person Show, Deitch Projects, New York, New York, United States</p>
          <p>- Keith Haring in Pisa, Palazzo Lanfranchi, Pisa, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- One Person Show, Wetterling Gallery, Stockholm, Sweden</p>
          <p>- One Person Show, Wetterling Teo Gallery, Singapore</p>
          <p>- Keith Haring: A Retrospective, Museum of Contemporary Art, Miami, Florida, United States</p>
          <p>- Keith Haring: A Retrospective, San Francisco Museum of Modern Art, San Francisco, California, United States</p>
          <p>- Keith Haring: A Retrospective, Musée des Beaux-Arts, Montréal, Canada</p>
          <p>- Complete Editions on Paper, Museum Moderner Kunst, Passau, Germany</p>
          <p>- Compete Editions on Paper, Quartier 206, Berlin, Germany</p>
          <p>- One Person Show, Dorothy Blau Gallery, Miami, Florida, United States</p>
          <p>- Keith Haring in San Francisco, San Francisco, California, United States</p>
          <p>- Keith Haring in West Hollywood, West Hollywood, California, United States</p>
          <p>- One Person Show, Tasende Gallery, Los Angeles, California, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Complete Editions on Paper, Kunsthalle, Cologne, Germany</p>
          <p>- Complete Editions on Paper, Historisches Museum der Pfalz-Speyer, Speyer, Germany</p>
          <p>- Complete Editions on Paper, Kunsthalle, Dresden, Germany</p>
          <p>- Keith Haring: A Retrospective, Whitney Museum of American Art, New York City, New York, United States</p>
          <p>- Keith Haring : A Retrospective, Art Gallery of Ontario, Toronto, Canada</p>
          <p>- Keith Haring on Park Avenue, New York, New York, United States</p>
          <p>- One Person Show, Andre Emmerich Gallery, New York, New York, United States</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <p>- One Person Show, Seagram Gallery, New York City, New York, United States</p>
          <p>- Keith Haring: Blurred Boundaries, Lobby Gallery, 717 Fifth Avenue, New York City, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- One Person Show, Andre Emmerich Gallery, New York, New York, United States</p>
          <p>- Complete Editions on Paper, Museum Bochum, Leipzig, Germany</p>
          <p>- Complete Editions on Paper, Museum der Bildenden, Leipzig, Germany</p>
          <p>- The 10 Commandments, Bruder Kirche und Museum Fridericianum, Kassel, Germany</p>
          <p>- One Person Show, Dorothy Blau, Miami, Florida, United States</p>
          <p>- Keith Haring: A Retrospective, Museum of Contemporary Art, Sydney, Australia </p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Keith Haring Altarpiece, Palo Alto Cultural Center, Palo Alto, California, United States</p>
          <p>- Keith Haring Altarpiece, Museum of Contemporary Religious Art, St. Louis, Missouri, United States</p>
          <p>- Keith Haring: A Retrospective, Fundacion La Caixa, Madrid, Spain</p>
          <p>- Keith Haring : A Retrospective, Kunsthaus Wien, Vienna, Austria</p>
          <p>- Keith Haring: Works on Paper 1989, Andre Emmerich Gallery, New York, New York, United States</p>
          <p>- One Person Show, Hans Mayer Gallery, Dusseldorf, Germany</p>
          <p>- One Person Show, Galerie Krinzinger, Vienna, Austria</p>
          <p>- Keith Haring: Family and Friends Collect, Allentown Art Museum, Allentown, Pennsylvania, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Keith Haring, Castellodi Rivoli, Turin, Italy</p>
          <p>- Keith Haring, Malmo Konsthall, Malmo, Sweden</p>
          <p>- Keith Haring, Deichtorhallen, Hamburg, Germany</p>
          <p>- Keith Haring, Tel Aviv Museum, Tel Aviv, Israel</p>
          <p>- Complete Editions on Paper, Hiroshima, Japan</p>
          <p>- Complete Editions on Paper, Osaka, Japan</p>
          <p>- Complete Editions on Paper, Nagoya, Japan</p>
          <p>- Complete Editions on Paper, Tokyo and Fukuoka, Japan</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- One Person Show, Queens Museum, Queens, New York, United States</p>
          <p>- One Person Show, DIA Art Foundation, Bridgehampton, New York, United States</p>
          <p>- Complete Editions on Paper, Galerie Littmann, Basel, Switzerland</p>
          <p>- Complete Editions on Paper, Galerie der Stadt, Stuttgart, Germany</p>
          <p>- Complete Editions on Paper, Aktionsforum, Munich, Germany</p>
          <p>- Keith Haring: A Retrospective, Mitsukoshi Museum of Art, Tokyo, Japan</p>
          <p>- One Person Show, Galerie Nikolaus Sonne, Berlin, Germany</p>
          <p>- One Person Show, Musée de Louvain-la-Neuve, Louvain-la-Neuve, Belgium</p>
          <p>- One Person Show, Michael Fuchs Gallery, Berlin, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- One Person Show, Gallery 56, Budapest, Hungary</p>
          <p>- One Person Show, Tabula Gallery, Tubingen, Germany</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- One Person Show, Hete Hunermann Gallery, Dusseldorf, Germany</p>
          <p>- Keith Haring: Future Primeval, University Galleries, Normal, Illinois, United States</p>
          <p>- Keith Haring: Future Primeval, Tampa Museum of Art, Tampa, Florida, United States</p>
          <p>- Keith Haring: Future Primeval, Museum of Modern Art, Ostend, Belgium</p>
          <p>- Haring, Disney, Warhol, Phoenix Art Museum, Phoenix, Arizona, United States</p>
          <p>- Haring, Disney, Warhol, Tacoma Art Museum, Tacoma, Washington, United States</p>
          <p>- Haring, Disney, Warhol, The Corcoran Gallery of Art, Washington, D.C., United States</p>
          <p>- Haring, Disney, Warhol, Worcester Art Museum, Worcester, Massachusetts, United States</p>
          <p>- One Person Show, Dante Park, Lincoln Center, New York, New York, United States</p>
          <p>- One Person Show, Chase Manhattan Bank, Soho Branch, New York, New York, United States</p>
          <p>- One Person Show, Erika Meyerovich Gallery, San Francisco, California, United States</p>
          <p>- One Person Show, Molinar Gallery, Scottsdale, Arizona, United States</p>
          <p>- One Person Show, Steffanoni Gallery, Milan, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- One Person Show, Galerie La Poche, Paris, France</p>
          <p>- One Person Show, Charles Lucien Gallery, New York, New York, United States</p>
          <p>- One Person Show, Philip Samuels Fine Art, St. Louis, Missouri, United States</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <p>- Keith Haring: Future Primeval, Queens Museum, Queens, New York, United States</p>
          <p>- One Person Show, Galerie Nikolaus Sonne, Berlin, Germany</p>
          <p>- One Person Show, Richard Nadeau Gallery, Philadelphia, Pennsylvania, United States</p>
          <p>- One Person Show, Gallery 56, Geneva, Switzerland</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- One Person Show, Gallery 121, Antwerp, Belgium</p>
          <p>- One Person Show, Casa Sin Nombre, Santa Fe, New Mexico, United States</p>
          <p>- One Person Show, Fay Gold Gallery, Atlanta, Georgia, United States</p>
          <p>- One Person Show, Galerie Hete Hunermann, Dusseldorf, Germany</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- One Person Show, Michael Kohn Gallery, Los Angeles, California, United States</p>
          <p>- One Person Show, Hans Mayer Gallery, Dusseldorf, Germany</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <p>- One Person Show, Hokin Gallery, Bay Harbor Islands, Florida, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <p>- One Person Show, Galerie Kaj Forsblom, Helsinki, Finland</p>
          <p>- One Person Show, Gallery 121, Antwerp, Belgium</p>
          <p>- One Person Show, Casino Knokke, Knokke, Belgium</p>
          <p>- One Person Show, Kutztown New Arts Program, Kutztown, Pennsylvania, United States</p>
          <p>- One Person Show, Galerie Rivolta, Lausanne, Switzerland</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- One Person Show, Dag Hammarskjold Plaza Sculpture Garden, New York, New York, United States</p>
          <p>- Art in the Park, Whitney Museum of American Art, Stamford, Connecticut, United States</p>
          <p>- One Person Show, Galerie Daniel Templon, Paris, France</p>
          <p>- One Person Show, Stedelijk Museum, Amsterdam, Netherlands</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- One Person Show, Schellmann & Kluser, Munich, West Germany</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <p>- One Person Show, Leo Castelli Gallery, New York, New York, United States</p>
          <p>- One Person Show, Museum of Contemporary Art, Bordeaux, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- One Person Show, University Museum of Iowa City, Iowa City, Iowa, United States</p>
          <p>- One Person Show, Paul Maenz Gallery, Cologne, West Germany</p>
          <p>- Keith Haring: Milan 1984, Salvatore Ala Gallery, Milan, Italy</p>
          <p>- One Person Show, Paradise Garage, New York, New York, United States</p>
          <p>- One Person Show, Galerie Corinne Hummel, Basel, Switzerland</p>
          <p>- One Person Show, Semaphore East, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- One Person Show, Fun Gallery, New York, New York, United States</p>
          <p>- One Person Show, Galerie Watari, Tokyo, Japan</p>
          <p>- One Person Show, Lucio Amelio Gallery, Naples, Italy</p>
          <p>- Matrix 75, Wadsworth Atheneum, Hartford, Connecticut, United States</p>
          <p>- One Person Show, Robert Fraser Gallery, London, United Kingdom</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- One Person Show, Rotterdam Arts Council, Rotterdam, Holland</p>
          <p>- One Person Show, Tony Shafrazi Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Des Refuses: New Drawings by Keith Haring Black-Blue Prints, Westbeth Painters Space, New York, New York, United States</p>
          <p>- I Am, Keith Haring New Drawings, Club 57, New York, New York, United States</p>
          <p>- Keith Haring, Hal Bromm Gallery, New York, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>1978</p>
          <p>- One Person Show, Pittsburgh Center for the Arts, Pittsburgh, Pennsylvania, United States</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Pop power from Warhol to Koons: Masterworks from the Collections of Jordan D. Schnitzer and his family foundation, Yellowstone art museum, Billings, Montana, USA.</p>
          <p>- Thinking Through Drawing: South Bethlehem Greenway, Lehigh University art Gallery (LUAG), Bethlehem, Pennsylvania, USA.</p>
          <p>- Building a Legacy: Chrysler Collects for the future, The Chrysler Museum of Art, Norfolk Virginia, USA.</p>
          <p>- Winter Bluechip Exhibition, Gormleys Fine Arts Dublin, Dublin, Ireland.</p>
          <p>- The 80s, Art of the Eighties, Albertina Modern, Vienna, Autria.</p>
          <p>- 2022 New Paintings Photographs Prints, Sculptures, HK Art Advisory + Projects, Los Angeles, California, USA.</p>
          <p>- Concrete to canvas, West Chelsea Contemporary, Austin, TX, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Five, Maddox Gallery, London, UK.</p>
          <p>- Writing The Future, Basquiat and the Hip-Hop Generation, Museum of Fine Arts, Boston, Massachusetts, USA.</p>
          <p>- ONLINE: Pride, Ikon LTD, Santa Monica, California, USA.</p>
          <p>- The Monaco Master Show, Monaco, Monaco.</p>
          <p>- Emerging to Established 12TH Annual Summer Group Show, Krause Gallery, New YOrk, USA.</p>
          <p>- Intersect, Opera Gallery, Geneva, Geneva, Switzerland.</p>
          <p>- Autumn Bluechip Exhibition, Gormleys Art Dublin, Dublin Ireland.</p>
          <p>- The Voyage of Life: Art, Allegory, and Community Response, Reynolda House Museum of American Art, Winston Salem, North Carolina, USA.</p>
          <p>- Opening Perrotin Secondary Market, Galerie Perrotin, Paris, France.</p>
          <p>- Summer Shade, Robert Fontaine Gallery, Palm Beach, Florida, USA.</p>
          <p>- Icons Vandals, West Chelsea Contemporary, Austin, TX, USA.</p>
          <p>- Spring Bluechip Exhibition, Gormleys Fine Arts, Belfast, United Kingdom.</p>
          <p>- Resistance, Maruani Mercier Gallery, Knokke, Belgium.</p>
          <p>- Mr. Brainwash and Kitzbuehel Winter Crossover, Galerie Frank Fluegel Kitbuehel, Australia.</p>
          <p>- Political Prints, Multiples from the 1960s to Today, Alpha 137 Gallery, New York, NY, USA.</p>
          <p>- FIGURATIVE-STILL-LIFE-OBJECTIVITY, Rosenfeld Gallery, Miami, Florida, USA.</p>
          <p>- I see Faces, Gazelli Art House, London,UK.</p>
          <p>- On the Bright Side, Opera Gallery, Paris, France.</p>
          <p>- On the Bright Side, Opera Gallery, Geneva, Switzerland.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Art After Stonewall, 1969-1989, Columbus Museum of Art</p>
          <p>- Keith Haring & William S. Burroughs: Apocalypse, Pace Prints, online exhibition</p>
          <p>- Keith Haring / Jean-Michel Basquiat: Crossing Lines, National Gallery of Victoria, Melbourne, Visite virtual exhibition</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Art After Stonewall, 1969-1989, Phillip Frost Museum, Miami, FL</p>
          <p>- Basquiat’s “Defacement”: The Untold Story, Guggenheim, New York, United States</p>
          <p>- Letting Loose and Fighting Back: LGBT Nightlife Before and After Stonewall, New-York Historical Society - Art After Stonewall, 1969-1989, NYU Grey Art Gallery and Leslie-Lohman Museum of Gay and Lesbian Art</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Night Fever. Designing Club Culture 1960 – Today, ADAM, Brussels Design Museum</p>
          <p>- Andy Warhol – From A to B and Back Again, Whitney Museum of American Art</p>
          <p>- Hello World. Revising a Collection, Hamburger Banhof – Museum fur Gegenwert, Berlin</p>
          <p>- Night Fever. Designing Club Culture 1960 – Today, Vitra Design Museum, Weil am Rhein, Germany Art in the Open: Fifty Years of Public Art in New York</p>
          <p>- Museum of the City of New York, New York, NY, United States</p>
          <p>- Club 57: Film, Performance, and Art in the East Village, 1978–1983, Museum of Modern Art, New York, NY, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Power Mask, Wereld Museum, Rotterdam,</p>
          <p>- Medusa: Jewellery & Taboos, Musée d’Art moderne, Paris, France</p>
          <p>- SerpentiForm: Snake Through Art, Jewelry, and Design, ArtScience Museum, Singapore</p>
          <p>- SerpentiForm: Snake Through Art, Jewelry, and Design, Mori Tower, Tokyo, Japan</p>
          <p>- Fast forward: Painting from the 1980s, Whitney Museum of American Art, New York, New York</p>
          <p>- Stiftung Sammlung Kurt Fried Kunst erleben, Ulmer Museum, Ulm, Germany</p>
          <p>- Winning the Whitehouse: From Press Prints to Selfies, Mana Contemporary, Jersey City, NJ</p>
          <p>- Summer Rental. Die Sammlung Marx zu Gast in Wroclaw, The Four Domes Pavilion, Wroclaw, Poland</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Tseng Kwong Chi: Performing for the Camera, Mary and Leigh Block Museum of Art, Evanston, IL</p>
          <p>- More than Words, Westport Arts Center, Westport, CT</p>
          <p>- Art AIDS America, Bronx Museum of the Arts, New York, NY</p>
          <p>- Winning the Whitehouse: From press Prints to Selfies, Southampton Arts Center, Southampton, NY</p>
          <p>- MashUp: The Birth of Modern Culture, Vancouver Art Gallery, Canada</p>
          <p>- SerpentiForm: Snake Through Art, Jewelry, and Design, Museo di Roma, Palazzo Braschi, Rome, Italy</p>
          <p>- Powerful Babies: Keith Haring’s Impact on Artists Today, Spritmuseum, Stockholm</p>
          <p>- In Living Color: Andy Warhol and Contemporary Printmaking, Museum of Contemporary Art Jacksonville, FL</p>
          <p>- Tseng Kwong Chi: Performing for the Camera, Tufts University Art Gallery, Medford, MA</p>
          <p>- Mother of the Year, Between Empowerment and Crisis: Images of Motherhood from 1900 to Today, Lentos Museum, Linz, Austria</p>
          <p>- Art, AIDS, America, Tacoma Art Museum, Tacoma, WA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- If You Leave Me Can I Come Too?, Hunter East Harlem Gallery, New York, NY, United States</p>
          <p>- Graffiti: New York Meets the Dam, Amsterdam Museum, Netherlands</p>
          <p>- Tseng Kwong Chi: Performing for the Camera, Chrysler Museum of Art, Norfolk, VA</p>
          <p>- How Posters Work, Cooper Hewitt, New York, NY, United States</p>
          <p>- The Wall in Our Heads: America and the Berlin Wall, Haverford College, Haverford, PA</p>
          <p>- Party Out of Bounds: Nightlife as Activism Since 1980, La MaMa Galleria, New York, NY, United States</p>
          <p>- Collecting Contemporary 1960 – 2015: Selections from the Schorr Collection, Princeton University Art Museum, Princeton, NJ</p>
          <p>- In Living Color: Andy Warhol and Contemporary Printmaking, Tampa Museum of Art, Tampa, FL</p>
          <p>- America is Hard to See, Whitney Museum of American Art, New York, NY, United States</p>
          <p>- Tseng Kwong Chi: Performing for the Camera, Grey Art Gallery, NYU, New York, NY</p>
          <p>- Embracing Modernism: Ten Years of Drawing Acquisitions, Morgan Library & Museum, New York, NY</p>
          <p>- Art & AIDS: Amor y Pasión, Leslie-Lohman Museum of Gay and Lesbian Art, New York, NY</p>
          <p>- Post Pop: East Meets West, Saatchi Gallery, London</p>
          <p>- Pop to Popism, Art Gallery of New South Wales, Sydney, Australia</p>
          <p>- Collectors, MAMAC Nice, France</p>
          <p>- After an Early Death, Staatliche Kunsthalle Baden-Baden, Germany</p>
          <p>- ASTRONOMY. Incursiones en el cosmos, La Casa Encendida Madrid, Spain</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- The Wall in Our Heads, Goethe-Institut, Washington D.C.</p>
          <p>- Bad Thoughts: Collection Martijn and Jeannette Sanders, Stedelijk Museum, Amsterdam</p>
          <p>- Urban Theater: New York Art in the 1980s, Modern Art Museum of Fort Worth, Fort Worth, TX</p>
          <p>- In Living Color: Andy Warhol & Contemporary Printmaking, Joslyn Art Museum, Omaha, NE</p>
          <p>- Strut Your Stuff, Dansenshus, Stockholm, Sweden</p>
          <p>- Shaping a Collection: Five Decades of Gifts, Whitney Museum of American Art, New York, NY</p>
          <p>- City as Canvas: Graffiti Art from the Martin Wong Collection, Museum of the City of New York, New York, NY, United States</p>
          <p>- Idolatry: What Sacred Games Shall We Have to Invent?, Blue Project Foundation, Barcelona, Spain</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Jesus Reloaded, Museen-Stade Kunsthaus, Stade, Germany</p>
          <p>- Safe Sex Bang: The Buzz Bense Collection of Safe Sex Posters, Center for Sex & Culture, San Francisco, CA</p>
          <p>- Stars: Contemporary Prints by Derriere L’Etoile Studio, Zimmerli Museum, Rutgers University, New Brunswick, NJ</p>
          <p>- Graphic Alert: AIDS posters from Around the World, MSB Gallery, NYU Langone Medical Center, New York, NY</p>
          <p>- AIDS in New York: The First Five Years, New York Historical Society, New York, NY</p>
          <p>- I, You, We, Whitney Museum of American Art, New York, NY</p>
          <p>- City as Canvas: New York Graffiti from the Martin Wong Collection, Museum of the City of New York, NY</p>
          <p>- Art/Activism, Clyfford Chance Law Firm, New York, NY, United States</p>
          <p>- Stars: Derriere l’Etoile Prints, Zimmerli Art Museum, Rutgers University, New Brunswick, NJ</p>
          <p>- Strut Your Stuff, HAU, Hebbel am Ufer, Berlin, Germany</p>
          <p>- Op Und Pop: Experimente Amerikanischer Kunstler AB 1960, Staatsgalerie, Stuttgart, Germany</p>
          <p>- Love is Colder than Capital, Kunsthaus Bregenz, Bregenz, Austria</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Bronx Lab: Style Wars, Bronx Museum of the Arts, Bronx, NY</p>
          <p>- 30 Years with Fashion, Design and HIV, Rohsska Museet, Gothenburg, Sweden</p>
          <p>- Warhol: Headlines, Museum fur Moderne Kunst, Frankfurt, Germany</p>
          <p>- Warhol: Headlines, Galleria nazionale darte moderna, Rome, Italy</p>
          <p>- Warhol: Headlines, The Andy Warhol Museum, Pittsburgh, Pennsylvania, United States</p>
          <p>- The Naked Man, Lentos Art Museum, Linz, Austria</p>
          <p>- The Robert H. and Clarice Smith New York Gallery of American History, New York Historical Society, New York, New York, United States</p>
          <p>- Hide Seek: Difference and Desire in American Portraiture, Tacoma Art Museum, Tacoma, WA, United States</p>
          <p>- Paintings from the Rubell Family Collection, Fundación Banco Santander, Madrid, Spain</p>
          <p>- Printin’, Museum of Modern Art, New York, New York, United States</p>
          <p>- This Will Have Been: Art, Love & Politics in the 1980’s, Museum of Contemporary Art, Chicago, Illinois, United States</p>
          <p>- This Will Have Been: Art, Love & Politics in the 1980’s, Walker Art Center, Minneapolis,Minnesota, United States</p>
          <p>- This Will Have Been: Art, Love & Politics in the 1980’s, Institute of Contemporary Art, Boston, Massachusetts, United States</p>
          <p>- Bronx Lab: Style Wars, Bronx Museum of the Arts, Bronx, New York, United States</p>
          <p>- Come Closer: Art Around the Bowery, New Museum, New York, New York, United States</p>
          <p>- HARING mania, Espacio UFI Union Fonografica Independiente, Madrid, Spain</p>
          <p>- Dogs and Pigs!, Family Business Gallery, New York, NY, United States</p>
          <p>- Times Square Show Revisited, Hunter College Art Galleries, New York, NY, United States</p>
          <p>- Crossing Houston, SMART CLOTHES GALLERY, New York, NY, United States</p>
          <p>- A Collection of Prominent Works, Collectors Contemporary, 5 Jalan Kilang Barat, Singapore</p>
          <p>- We the People, Robert Rauschenberg Foundation Project Space, New York, NY, United States</p>
          <p>- 30 Years with Fashion, Design and HIV, Rohsska Museet, Gothenburg, Sweden</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Looking at Music 3.0, Museum of Modern Art, New York City, New York, United States</p>
          <p>- Legacy: The Emily Fisher Landau Collection, Whitney Museum of American Art, New York City, New York, United States</p>
          <p>- Fantasy comes to Power: Politics of Artist’s Posters, Museum fuer Kunst und Gewerbe, Hamburg, Germany</p>
          <p>- Art, Access & Decay: NY 1975-1985, SUBLIMINAL PROJECTS, Los Angeles, California, United States</p>
          <p>- Art in the Streets, Museum of Contemporary Art, Los Angeles, California, United States</p>
          <p>- Warhol: Headlines, National Gallery of Art, Washington DC, New York, United States</p>
          <p>- Graffiti New York ’80s, Galerie Jérôme de Noirmont, Paris, France</p>
          <p>- American Exuberance, Rubell Family Collection / Contemporary Arts Foundation, Miami, Florida, United States</p>
          <p>- Make Art in Public, Children's Museum of the Arts, New York, New York, United States</p>
          <p>- East Village West, Royal/T, Culver City, Los Angeles, California, United States</p>
          <p>- Contemporary Art from the Collection, Museum of Modern Art, New York, New York, United States</p>
          <p>- Figure libre, Musée d'Art Moderne et d’Art Contemporain, Galerie des Ponchettes, Nice, France</p>
          <p>- Hide Seek: Difference and Desire in American Portraiture, Brooklyn Museum, Brooklyn, New York, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- C'est la vie! – Vanities from Caravaggio to Damien Hirst, Musée Maillol, Paris, France</p>
          <p>- Rubbers: the Life, History & Struggle of the Condom, Museum of Sex, New York, New York, United States</p>
          <p>- POP LIFE: Art in a Material World, Hamburger Kunsthalle, Hamburg, Germany</p>
          <p>- POP LIFE: Art in a Material World, National Gallery, Ottawa, Canada</p>
          <p>- The Street as Studio, Kunsthalle Wien, Vienna, Austria</p>
          <p>- The 80s Revisited. Collection Bischofberger I, Kunsthalle Bielefeld, Bielefeld, Germany</p>
          <p>- Tseng Kwong Chi (in collaboration with Bill T. Jones and Keith Haring), Paul Kasmin Gallery, New York, New York, United States</p>
          <p>- Here Comes the Sun: Warhol and Art after 1960 in the Norton Collection, Norton Museum of Art, West Palm Beach, Florida, United States</p>
          <p>- Revealed: The Tradition of Male Homoerotic Art, Central Connecticut State University Art Galleries, New Britain, Connecticut, United States</p>
          <p>- The Leslie/Lohman Gay Art Foundation, New York, New York, United States, Now is the Time, Black Rat Projects, London, United Kingdom</p>
          <p>- Hide Seek: Difference and Desire in American Portraiture, National Portrait Gallery, Washington DC, United States</p>
          <p>- IN THE NAME OF POP: WARHOL, HARING, KOONS IN THE 80s, Fabrik Gallery, Hong Kong, China</p>
          <p>- Summer Group Show, Skarstedt Gallery, New York, New York, United States</p>
          <p>- Time Capsule, Age 13 to 21: The Contemporary Art Collection of Jason Rubell, RubellFamily Collection, Miami, Florida, United States</p>
          <p>- Graphic Intervention: 25 Years of International AIDS Awareness Posters 1985-2010, Stephen D. Paine Gallery, Massachusetts College of Art & Design, Boston, Massachusetts, United States</p>
          <p>- 25th Anniversary Show: 1985-2010, Michael Kohn Gallery, Los Angeles, California, United States</p>
          <p>- Brion Gysin: Dream Machine, Institut d'Art Contemporain in Villeurbanne, Villeurbanne, France</p>
          <p>- Brion Gysin: Dream Machine, New Museum, New York, New York, United States</p>
          <p>- American Pop Posters, Opelvillen Foundation, Ruesselsheim, Germany</p>
          <p>- The Global Africa Project, Museum of Arts and Design, New York, New York, United States</p>
          <p>- Decadence Now!, Galerie Rudolfinum, Prague, Czech Republic</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Innovation in the Third Dimension: Sculpture of Our Time, Bruce Museum, Greenwich, Connecticut, United States</p>
          <p>- Mouton Rothschild: Paintings for the Labels, The Wolfsonian-FIU, Miami Beach, Florida, United States</p>
          <p>- Le Siècle du Jazz, Musée du quai Branly, Paris, France</p>
          <p>- Andy Warhol Portfolios: Life and Legends, Museum of Fine Arts, St Petersburg, Florida, United States</p>
          <p>- Born in the Street: Graffiti, Fondation Cartier, Paris, France</p>
          <p>- Big Shots: Andy Warhol, Celebrity Culture and the 1980s, Spencer Museum of Art, University of Kansas, Lawrence, Kansas, United States</p>
          <p>- Vraoum! Comics and Contemporary Art, La Maison Rouge, Paris, France</p>
          <p>- ACT UP New York: Activism, Art, and the AIDS Crisis, 1987, Carpenter Center for the Visual Arts and Harvard Art Museum, Cambridge, Massachusetts, United States</p>
          <p>- Pop Life: Art in a Material World, Tate Modern, London, United Kingdom</p>
          <p>- The 80s: The Triumph of Painting from Schifano to Basquiat, Serrone della Villa Reale, Monza, Italy</p>
          <p>- Andy Warhol: The Last Decade, Milwaukee Art Museum, Milwaukee, Wisconsin, United States</p>
          <p>- Bijoux D’Artistes, Musée du Temps, Besançon, France</p>
          <p>- Apocalypse Wow! Pop Surrealism, Neo Pop, Urban Art, Museo D'Arte Contemporanea, Rome, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Artists Intervene – Politically Committed Poster Art from Picasso to the Present Day, Kathe Kollwitz Museum, Cologne, Germany</p>
          <p>- Grosse Amerikaner, Kunsthalle Weishaupt, Ulm, Germany</p>
          <p>- Totally Rad: New York in the 80’s, Paul Kasmin Gallery, New York, New York</p>
          <p>- Now’s The Time, Dublin City Gallery, Dublin, Ireland</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Sign Language, Des Moines Art Center , Des Moines, Iowa, United States</p>
          <p>- That 80s Print Show, Hartje Gallery, Boston, MA, United States</p>
          <p>- The Outdoor Gallery: 40 Years of Public Art in New York City Parks, Arsenal Gallery, Arsenal at Central Park, New York, New York, United States</p>
          <p>- 30th Anniversary Exhibition: Carlos Alfonzo, Luis Frangella, Keith Haring and David Wojnarowicz, Hal Bromm Gallery, New York, New York, United States</p>
          <p>- ART IN AMERICA: 300 Years of Innovation, National Art Museum of China, Beijing, China</p>
          <p>- ART IN AMERICA: 300 Years of Innovation, Museum of Contemporary Art, Shanghai, China</p>
          <p>- ART IN AMERICA: 300 Years of Innovation, Guggenheim Museum, Bilbao, Spain</p>
          <p>- Four Friends, Tony Shafrazi Gallery, New York, NY, United States</p>
          <p>- Personal Jesus...: The Religious Art of Keith Haring and Andy Warhol , The Warhol Museum, Pittsburgh, Pennsylvania, United States</p>
          <p>- The Eclectic Eye: Selections of Fantasy and Illusion from Frederick R. Weisman Art Foundation, New Orleans Contemporary Art Museum, New Orleans, Louisiana, United States</p>
          <p>- War & Discontent, Museum of Fine Arts, Boston, MA, United States</p>
          <p>- Panic Attack! Art in the Punk Years, Barbican Art Gallery, London, England</p>
          <p>- Contemporary Currents: Selections from the Bank of America Collection, Museum of Contemporary Art, Jacksonville, Florida, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Black & White, Hal Bromm Gallery, New York, NY, United States</p>
          <p>- Max Lang Gallery, New York, NY, United States</p>
          <p>- Mozart: Experimentation and Enlightenment in Late 18th Century Vienna, Albertina Museum, Vienna, Austria</p>
          <p>- Where Are We Going?, Selections from the François Pinault Collection, Palazzo Grassi, Venice, Italy</p>
          <p>- New York, New York: Fifty Years of Art, Architecture, Photography Film and Video, Grimaldi Forum, Monte Carlo, Monaco</p>
          <p>- Il était une fois Walt Disney, Grand Palais, Paris</p>
          <p>- Il était une fois Walt Disney, Musée des Beaux-Arts, Montréal, Canada</p>
          <p>- Best in Show: Dogs in Art from the Renaissance to the Present, Bruce Museum, Greenwich, Connecticut</p>
          <p>- Best in Show: Dogs in Art from the Renaissance to the Present, Museum of Fine Arts, Houston, Texas</p>
          <p>- Pop Goes the Press: Prints from the Permanent Collection, 1960-1990, Allentown Art Museum, PA</p>
          <p>- When We Were Young: New Perspectives on the Art of the Child, Phillips Collection, Washington, D.C.</p>
          <p>- When We Were Young: New Perspectives on the Art of the Child, Krannert Art Museum, University of Illinois at Urbana-Champaign</p>
          <p>- The Flipbook Show, FotoMuseum, Antwerp, Belgium</p>
          <p>- Motion on Paper, Ben Brown Fine Arts, London, UK</p>
          <p>- Weltsprache Abstraktion: Gestalt, Magie, Zeichen, Nationalgalerie, Berlin, Germany</p>
          <p>- Figuration and Abstraction, Vonderbank Gallery, Berlin, Germany</p>
          <p>- Faster! Bigger! Better!, ZKM/Museum für Neue Kunst, Karlsruhe, Germany</p>
          <p>- Mass Production: Artist’s Multiples and the Marketplace, Emily David Gallery of the Myers School of Art, University of Akron, Ohio</p>
          <p>- Hot Commodities: Art in the ’80s, Bank of America Gallery, Hearst Tower, Charlotte, NC</p>
          <p>- Late Night University: College Night at the Kemper, East Meets West, Kemper Museum of Contemporary Art, Kansas City, MO</p>
          <p>- The Downtown Show: The New York Art Scene 1974-1984, Grey Art Gallery, New York University, New York, NY, United States</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- 20th Anniversary Exhibition, Haggerty Museum, Marquette University, Milwaukee, Wisconsin</p>
          <p>- Beautiful Losers, Orange County Museum of Art, Newport Beach, CA</p>
          <p>- A Decade of Art, Kantor Gallery, Los Angeles, California</p>
          <p>- Surfing the Century: Twentieth-Century Art, University of Michigan Museum of Art, Ann Arbor, Michigan</p>
          <p>- A Very Personal Collection, Bruce Museum of Arts and Science, Greenwich, Connecticut</p>
          <p>- ATOMICA: Making the Invisible Visible, Esso Gallery and Lombard-Freid Fine Arts, New York, NY</p>
          <p>- Daumen Kino: The Flip Book Show, Kunsthalle Dusseldorf, Germany</p>
          <p>- Perfect Painting: 40 Years, Galerie Hans Mayer, Langen Foundation, Dusseldorf, Germany</p>
          <p>- Picturing America: Selections from the Whitney Museum of American Art, Nagasaki Prefectural Museum, Japan</p>
          <p>- Picturing America: Selections from the Whitney Museum of American Art, Fuchu City Museum, Japan</p>
          <p>- Picturing America: Selections from the Whitney Museum of American Art, Twenty First Century Museum of Contemporary Art, Japan</p>
          <p>- Picturing America: Selections from the Whitney Museum of American Art, Kitakyushu Municipal Museum of Art, Japan</p>
          <p>- Picturing America: Selections from the Whitney Museum of American Art, Koriyama City Museum, Japan</p>
          <p>- Self-Portraits, Per Skarstedt Gallery, New York, California</p>
          <p>- Andrea Rosen Gallery, New York, NY, United States</p>
          <p>- Dance of the Avant-Garde: Paintings, Sets and Costumes from Degas to Picasso, from Matisse to Keith Haring, MART, Rovereto, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Beautiful Losers, Yerba Buena Center for the Arts, San Francisco, California</p>
          <p>- Beautiful Losers, Contemporary Arts Center, Cinicinnati, Ohio</p>
          <p>- Crimes and Misdemeanors: Politics in U.S. Art of the 1980’s, Contemporary Arts Center, Cincinnati, Ohio</p>
          <p>- Warhol, Haring, Finster, Skot Foreman Fine Art, Atlanta, Georgia</p>
          <p>- American Art of the 1980s: Selections from the Broad Collections, Washington University Gallery of Art, St Louis, Missouri</p>
          <p>- Moi! Self-Portraits of the 20th Century, Musée du Luxembourg, Paris, France</p>
          <p>- Moi! Self-Portraits of the 20th Century, Galleria degli Uffizi, Florence, Italy</p>
          <p>- Through American Eyes: Two Centuries of American Art, Taft Museum of Art, Cincinnati, Ohio</p>
          <p>- Gyroscope, Hirshhorn Museum and Sculpture Garden, Washington, DC, United States</p>
          <p>- Third Festival of International Sculpture, Monte Carlo, Monaco</p>
          <p>- Beauty and the Beast: The Magic of the Instinctual in Art, Museum of Modern and Contemporary Art, Rovereto, Italy</p>
          <p>- Co-Conspirators: Artist and Collector, Orlando Museum of Art, Orlando, Florida</p>
          <p>- Beginning Here: 101 Ways, School of Visual Arts, New York, NY, United States</p>
          <p>- Rock 50, Palazzo delle Esposizioni, Rome</p>
          <p>- Andy Warhol: The Jon Gould Collection, Brattleboro Museum and Art Center, Brattleboro, Vermont</p>
          <p>- Marilyn: From William Anastasi to Weegee, Sean Kelly Gallery, New York, NY, United States</p>
          <p>- Proposta 2004, Centre de Cultura Contemporània de Barcelona, Spain</p>
          <p>- A Collection’s Vision, Museum der Moderne Salzburg, Austria</p>
          <p>- East Village USA, New Museum of Contemporary Art, New York, NY, United States</p>
          <p>- Happy Birthday!, Galerie Jérôme de Noirmont, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- From Picasso to Keith Haring, National Museum of Fine Arts, Havana, Cuba</p>
          <p>- Parthenogenesis, Museum of Contemporary Art, Sydney, Australia</p>
          <p>- Splat Boom Pow! The Influence of Cartoons in Contemporary Art, Contemporary Arts Museum, Houston, Texas, United States</p>
          <p>- For a Better World: Posters from the United Nations, Bruce Museum of Arts and Science, Greenwich, Connecticut</p>
          <p>- The Human Factor: Figuration in American Art, 1950-1995, Morris Museum, Newtown, Connecticut</p>
          <p>- The DaimlerChrysler Collection, Museum fur Neue Kunst - ZKM, Karlsruhe, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- New Painting, The Museum of Art, Kochi, Japan</p>
          <p>- PopJock: Warhol-Murakami, Museum of Contemporary Art, Denver, Colorado</p>
          <p>- I Love Art, Watari-um, Tokyo, Japan</p>
          <p>- I Love Art, Neue Galerie der Stadt Linz, Austria</p>
          <p>- Made in USA, Ludwig Museum, Oberhausen, Germany</p>
          <p>- New York Expression, Bergen Kunstmuseum, Bergen, Norway</p>
          <p>- My Marilyn, Liljevalchs Konsthall, Stockholm, Sweden</p>
          <p>- Magnet: Foreign Artists in Tuscany (1945-2000), Fattoria Di Celle, Santomato Di Pistoia, Italy</p>
          <p>- La Parade des Animaux, Monte Carlo Casino Gardens, Monaco</p>
          <p>- Von Zero bis 2002, Museum fur Neue Kunst/ZKM, Karlsruhe, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Tele[visions], Kunsthalle Wien, Austria</p>
          <p>- Recovering the Positive: Originals, Aronson Galleries, Parsons School of Design, New York City</p>
          <p>- Mythic Proportions: Painting in the 1980s, Museum of Contemporary Art, Miami, Florida</p>
          <p>- Artistic Alternatives: Art by Haring, Hockney, Johns, Rauschenberg and Warhol, Philadelphia Art Alliance, Pennsylvania</p>
          <p>- Aperto 2001, 4th International Sculpture Installation, Biennale di Venezia, Venice, Italy</p>
          <p>- Jewels in Our Crown: Treasures from the Wake Forest University Art Collections, 1941-2001, Wake Forest University, Winston-Salem, North Carolina</p>
          <p>- Jasper Johns to Jeff Koons: Four Decades of Art from the Broad Collections, Los Angeles County Museum of Art, California</p>
          <p>- Jasper Johns to Jeff Koons: Four Decades of Art from the Broad Collections, Corcoran Gallery of Art, Washington, D.C., United States</p>
          <p>- Jasper Johns to Jeff Koons: Four Decades of Art from the Broad Collections, Museum of Fine Arts, Boston, Massachusetts, United States</p>
          <p>- One Planet Under A Groove: Hip Hop and Contemporary Art, Bronx Museum of the Arts, New York</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Palazzo Bricherasio, Turin, Italy</p>
          <p>- Den Haag Sculptuur 2000, Lange Voorhout, The Hague, Holland</p>
          <p>- Rock Style, Rock and Roll Hall of Fame, Cleveland, Ohio</p>
          <p>- The UFO Show, University Galleries, Normal, Illinois</p>
          <p>- The UFO Show, Arts & Science Center for Southeast Arkansas</p>
          <p>- The UFO Show, University of Colorado, Colorado Springs,</p>
          <p>- The Figure: Another Side of Modernism, Newhouse Center for Contemporary Art, Staten Island, New York, NY, USA</p>
          <p>- DIRE AIDS: Art in the Age of AIDS, Castello di Rivoli, Torino, Italy</p>
          <p>- Around 1984: A Look at Art in the Eighties, P.S. 1 Museum, Long Island City, New York, NY, USA</p>
          <p>- Absolut Art, Vanderbilt Hall, Grand Central Terminal, New York, NY, USA</p>
          <p>- The Human Factor: Figuration in American Art, 1950-1995, Tallahassee Museum of Art, Florida, USA</p>
          <p>- Contemporary Art from Frederick R. Weisman Art Foundation, Frederick R. Weisman Museum of Art, Pepperdine University, Malibu, California, USA</p>
          <p>- Art at Work: 40 Years of the Chase Manhattan Collection, Queens Museum of Art, New York, NY, USA</p>
          <p>- The Intuitive Eye: Selections from the Frederick R. Weisman Collection, Fashion Institute of Design & Merchandising, Los Angeles, California, USA</p>
          <p>- Keith Haring and Jean-Michel Basquiat, Dorothy Blau Gallery, Miami, Florida, USA</p>
          <p>- Best of the Ewing, Ewing Gallery, Knoxville, Tennessee, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Auffrischender Wind aus wechselnden Richtungen, Museum der Kunstsammlungen zu Weimar, Germany</p>
          <p>- The Virginia and Bagley Wright Collection, Seattle Art Museum, Washington, USA</p>
          <p>- Les Champs de la Sculpture 2000, Avenue des Champs-Elysées, Paris, France</p>
          <p>- The American Century: Art & Culture 1900-2000, Whitney Museum of American Art, New York, NY, USA</p>
          <p>- Bow Wow! It’s a Dog’s Life, Bedford Gallery, Walnut Creek, California, USA</p>
          <p>- Rock Style, Costume Institute, Metropolitan Museum of Art, New York, NY, UUSA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- The Prophecy of Pop, Contemporary Arts Center, New Orleans, Louisiana, USA</p>
          <p>- Concept/Image/Object, Art Institute of Chicago, Illinois, USA</p>
          <p>- Art of the Eighties, Washington Gallery University of Art, St Louis, Missouri, USA</p>
          <p>- Art Performs Life, Walker Art Center, Minneapolis, Minnesota, USA</p>
          <p>- The Big Picture: American Art Since 1950, Allentown Art Museum, Pennsylvania, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- End of the Century: Prints Since 1970 From the Collection, Los Angeles County Museum of Art, Los Angeles, California, USA</p>
          <p>- Multiple Identity: Amerikanische Kunst 1975-1995 Aus Dem Whitney Museum of American Art, Kunstmuseum Bonn, Germany</p>
          <p>- Goodbye to Berlin? 100 Jahre Schwulenbewegung, Schwulen Museum-Akademie der Künste, Berlin, Germany</p>
          <p>- In Your Face: Basquiat, Haring, Scharf, Malca Fine Art, New York, USA</p>
          <p>- American Graffiti, Museo del Castelnuovo, Naples, Italy</p>
          <p>- American Graffiti, Chiostro del Bramante, Rome, Italy</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Private Passions, Musée d'Art Moderne de la Ville de Paris, France</p>
          <p>- Thinking Print: Books to Billboards, 1980-95, The Museum of Modern Art, New York, NY, USA</p>
          <p>- Thinking Print: Books to Billboards, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- Ports of Entry: William S. Burroughs & the Art, Los Angeles County Museum of Art, Los Angeles, California, USA</p>
          <p>- Art and Money, Galerie Schoen & Nalepa, Berlin, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- In a Different Light, University of California, Berkeley, California, USA</p>
          <p>- Matrix is 20!, Wadsworth Atheneum, Hartford, Connecticut, USA</p>
          <p>- A New York Time: Selected Drawings of the 80s, Bruce Museum, Greenwich, Connecticut, USA</p>
          <p>- Crossing State Lines: 20th Century Art from Private Collections in Westchester and Fairfield Counties, Neuberger Museum of Art, Purchase College, Purchase, New York, NY, USA</p>
          <p>- Recent 20th Century Acquisitions, The Bass Museum of Art, Miami, Florida, USA</p>
          <p>- Male Desire: Homoerotic Images in Twentieth Century American Art, Mary Ryan Gallery, New York, NY, USA</p>
          <p>- Americans in Venice: The Biennale Legacy, Arizona State University Art Museum, Nelson Fine Arts Center, Tempe, Arizona, USA</p>
          <p>- Unser Jahrhundert: Menschenbilder – Bilderwelten, Museum Ludwig, Cologne, Germany</p>
          <p>- Temporarily Possessed: The Semi-Permanent Collection, The New Museum of Contemporary Art, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Outside the Frame, Cleveland Center for Contemporary Art, Cleveland, Ohio, USA</p>
          <p>- Power Works from the MCA Collection, Museum of New Zealand, Wellington, New Zealand</p>
          <p>- Art’s Lament: Creativity in the Face of Death, Isabella Stewart Gardner Museum, Boston, Massachusetts, USA</p>
          <p>- New York Realism Past and Present, Tampa Museum of Art, Tampa, Florida and Japan Tour</p>
          <p>- Art in the Age of AIDS, National Gallery of Australia, Canberra, Australi a</p>
          <p>- 2X Immortal: Elvis & Marilyn, Institute of Contemporary Art, Boston, Massachusetts, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Contemporary Art Museum, Houston, Texas, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Mint Museum of Art, Charlotte, North Carolina, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Cleveland Museum of Art, Cleveland, Ohio, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Jacksonville Museum of Contemporary Art, Jacksonville,Florida, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Portland Museumof Art, Portland, Oregon, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Philbrook Museum of Art, Tulsa, Oklahoma, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Columbus Museum of Art, Columbus, Ohio,USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Tennessee State Museum, Nashville, Tennessee, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, San Jose Museum of Art, San Jose, California, USA</p>
          <p>- 2X Immortal: Elvis & Marilyn, Honolulu Academy of Art, Honolulu, Hawaii, USA</p>
          <p>- Significant Losses, University of Maryland Art Gallery</p>
          <p>- Keith Haring and Yoko Ono, Ken Frankel Gallery, Palm Beach, Florida, United States</p>
          <p>- Cedarhurst Sculpture Park, Mount Vernon, Illinois</p>
          <p>- Changing Your Mind: Drugs and the Brain, Museum of Science/Harvard Medical School, Boston, Massachusetts, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Socrates Sculpture Park, Long Island City, New York, NY, USA</p>
          <p>- Coming from the Subway: New York Graffiti Art, Groninger Museum, Groninger, Holland</p>
          <p>- American Art in the Twentieth Century: Painting and Sculpture, Martin Gropius Bau, Berlin Germany</p>
          <p>- American Art in the Twentieth Century: Painting and Sculpture, Royal Academy, London, England</p>
          <p>- Art Against AIDS, Peggy Guggenheim Museum, Venice, Italy</p>
          <p>- Art Against AIDS, Guggenheim Museum Soho, New York, NY, USA</p>
          <p>- Extravagance, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- Russian Cultural Center, Berlin, Germany</p>
          <p>- An American Homage to Matisse, Sidney Janis Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Allegories of Modernism, Museum of Modern Art, New York, NY, USA</p>
          <p>- The Power of the City/The City of Power, Whitney Museum of American Art, Downtown Branch, New York, NY, USA</p>
          <p>- Read My Lips: New York AIDS Polemic, Tramway Gallery, Glasgow, Scotland</p>
          <p>- From Media to Metaphor: Art About AIDS, Emerson Gallery, Hamilton College, Clinton, New York, NY, USA</p>
          <p>- From Media to Metaphor: Art About AIDS, Center of Contemporary Art, Seattle, Washington, USA</p>
          <p>- From Media to Metaphor: Art About AIDS, Sharadin Art Gallery, Kutztown University, Kutztown, Pennsylvania, USA</p>
          <p>- From Media to Metaphor: Art About AIDS, Musée d’Art Contemporain de Montréal, Montréal, Canada</p>
          <p>- From Media to Metaphor: Art About AIDS, Grey Art Gallery and Study Center, New York University, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Hommage à Jean Tinguely, Klaus Littmann Gallery, Basel, Switzerland</p>
          <p>- Myth and Magic in America: the 80s, Museo de Arte Contemporáneo de Monterrey, Monterrey, Mexico</p>
          <p>- La Couleur d’Argent, Musée de la Poste, Paris, France</p>
          <p>- Biennial, Whitney Museum of American Art, New York, NY, USA</p>
          <p>- I Love Art, Watari-Um, Tokyo, Japan</p>
          <p>- Compassion and Protest: Recent Social and Political Art from the Eli Broad Family Foundation Collection; San Jose Museum of Art, San Jose, California, USA</p>
          <p>- Devil on the Stairs: Looking Back on the 80s, Institute of Contemporary Art, Philadelphia, Pennsylvania, USA</p>
          <p>- Echt Falsch, Fondation Cartier, Paris, France</p>
          <p>- Cruciformity: Images of the Cross since 1980, Cleveland Center for Contemporary Art, Cleveland, Ohio, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Divergent Style: Contemporary American Drawing, University College of Fine Arts, University of Florida, Miami, Florida, USA</p>
          <p>- The Last Decade: American Artists of the 80s, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- Cornell Collects: A Celebration of American Art from the Collection of Alumni and Friends, Herbert F. JohnsonMuseum of Art, Cornell University, Ithaca, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Exposition Inaugurale, Fondation Daniel Templon, Fréjus, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Committed to Print, The Museum of Modern Art, New York, NY, USA</p>
          <p>- Committed to Print, Hokin Gallery, Bay Harbor Islands, Florida, USA</p>
          <p>- Committed to Print, Penson Gallery (collaboration with Gian Franco Gorgoni), New York, NY, USA</p>
          <p>- Committed to Print, Bernice Steinbaum Gallery, New York, NY, USA</p>
          <p>- Committed to Print, Leo Castelli Gallery, New York, NY, USA</p>
          <p>- Gran Pavese: The Flag Project, Museum van Hedendaagse Kunst, Antwerp, Belgium</p>
          <p>- Ideas from Individual Impressions and Marks, Lehigh University Art Gallery, Bethlehem, Pennsylvania, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Focus on the Image: Selections from the Rivendell Collection, Phoenix Art Museum, Arizona, USA</p>
          <p>- L'Époque, La Mode, La Morale, La Passion, Centre Georges Pompidou, Paris, France</p>
          <p>- Borrowed Embellishments, Kansas City Art Institute, Missouri, USA</p>
          <p>- Sculpture Project in Munster, Westfälisches Landesmuseum, Munster, Germany</p>
          <p>- Avant-Garde in the Eighties, Los Angeles County Museum of Art, California, United States</p>
          <p>- Kunst aus den achtziger Jahren, A II Art Forum Thomas, Munich, Germany</p>
          <p>- Comic Iconoclasm, Institute of Contemporary Art, London, UK</p>
          <p>- Leo Castelli and His Artists, Cultural Center of Contemporary Art, Polanco, Mexico</p>
          <p>- Computers and Art, Everson Museum of Art, Syracuse, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Life in the Big City: Contemporary Artistic Responses to the Urban Experience, Rhode Island School of Design,Providence, Rhode Island</p>
          <p>- An American Renaissance: Painting and Sculpture Since 1940, Fort Lauderdale Museum of Art, Florida, USA</p>
          <p>- Spectrum: The Generic Figure, The Corcoran Gallery of Art, Washington, D.C., USA</p>
          <p>- Masterworks on Paper, Galerie Barbara Farber, Amsterdam, Holland</p>
          <p>- American Art of the Eighties, Phoenix Art Museum, Arizona,USA</p>
          <p>- Sacred Images in Secular Art, Whitney Museum of American Art, New York , NY, USA</p>
          <p>- Surrealismo, Barbara Braathen Gallery, New York, NY, USA</p>
          <p>- Surrealismo, Gabrielle Bryars Gallery, New York, NY, USA</p>
          <p>- Surrealismo, Linda Ferris Gallery, Seattle, Washington, USA</p>
          <p>- Vienna Biennial, Vienna, Austria</p>
          <p>- Havana Biennial, Havana, Cuba</p>
          <p>- Television’s Impact on Contemporary Art, Queens Museum, New York, NY, USA</p>
          <p>- Television’s Impact on Contemporary Art, Thomas Cohn Gallery, Rio de Janeiro, Brazil</p>
          <p>- The First Decade, Freedman Gallery, Philadelphia, Pennsylvania, USA</p>
          <p>- Contemporary Screens: Function, Decoration, Sculpture, and Metaphor, The Art Museum Association of America, San Francisco, California, USA</p>
          <p>- What It Is, Tony Shafrazi Gallery, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- New York 85, ARCA, Marseille, France</p>
          <p>- Of the Street, Aspen Art Museum, Colorado, USA</p>
          <p>- Rain Dance, 292 Lafayette Street, New York, NY, USA</p>
          <p>- The Subway Show, Lehman College, Bronx, New York, NY, USA</p>
          <p>- La Biennale de Paris, Grand Palais, Paris, France</p>
          <p>- Homo Decorans, Louisiana Museum, Humlebaek, Denmark</p>
          <p>- Les Piliers de la Coupole, Galerie Beau Lézard, Paris, France</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Robert Fraser Gallery, London, U.K.</p>
          <p>- Biennial III-The Human Condition, San Francisco Museum of Modern Art, California, USA</p>
          <p>- Biennial III-The Human Condition, Aldrich Museum, Ridgefield, Connecticut, USA</p>
          <p>- Via New York, Montreal Museum of Contemporary Art, Montreal, Canada</p>
          <p>- Via New York, New Gallery of Contemporary Art, Cleveland, Ohio, USA</p>
          <p>- Arte di Frontiera, Galleria d’Arte Moderna, Bologna, Italy</p>
          <p>- Aperto 84, Venice Biennale, Italy</p>
          <p>- Content, Hirshhorn Museum, Washington, D.C, USA</p>
          <p>- Disarming Images: Art for Nuclear Disarmament, throughout United States, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- Disarming Images: Art for Nuclear Disarmament, throughout United States, Musée d’Art Moderne de la Ville de Paris, France</p>
          <p>- New Attitudes: Paris/New York, Pittsburgh Center for the Arts, Pennsylvania, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- The Black and White Show, Kenkeleba Gallery, New York, NY, USA</p>
          <p>- Morton G. Neumann Family Collection, Kalamazoo Institute of Arts, Kalamazoo, Michigan, USA</p>
          <p>- New York Painting Today, Carnegie-Mellon University, Pittsburgh, Pennsylvania, USA</p>
          <p>- Biennial 1983, Whitney Museum ofAmerican Art, New York, NY, USA</p>
          <p>- The Comic Art Show, Whitney Museum of American Art, Downtown Branch, New York, NY, USA</p>
          <p>- Back to the U.S.A., Kunstmuseum, Lucerne, Switzerland</p>
          <p>- Tendencias en Nueva York, Palacio Velazques, Madrid, Spain</p>
          <p>- Bienal de São Paulo, São Paulo, Brazil</p>
          <p>- Terrae Motus, Institute of Contemporary Art, Boston, Massachusetts, USA</p>
          <p>- Post-Graffiti Artists, Sidney Janis Gallery, New York, NY, USA</p>
          <p>- Currents, Institute of Contemporary Art, Philadelphia, Pennsylvania, USA</p>
          <p>- Currents, Pittsburgh Center for the Arts, Pennsylvania, with LA, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Still Modern After All These Years, Chrysler Museum, Norfolk, Virginia, USA</p>
          <p>- Fast, Alexander Milliken Gallery, New York, NY, USA</p>
          <p>- Young Americans, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- Larry Gagosian Gallery, Los Angeles, California, Blum Helman Gallery, New York, NY, USA</p>
          <p>- New Painting 1: Americans, Middendorf Lane Gallery, Washington, D.C. USA</p>
          <p>- Art of the 80’s, Westport Weston Arts Council, Westport, Connecticut, USA</p>
          <p>- Art of the 80’s, Wave Hill, Bronx, New York, NY, USA</p>
          <p>- Young Hoffman Gallery, Chicago, Illinois, USA</p>
          <p>- The Agitated Figure, Hall Walls, Buffalo, New York, NY, USA</p>
          <p>- Holly Solomon Gallery, New York, NY, USA</p>
          <p>- Painting & Sculpture Today, Indianapolis Museum of Art, Indiana, USA</p>
          <p>- Painting & Sculpture Today, Richard Hines Gallery, Seattle, Washington, USA</p>
          <p>- The Pressure to Paint, Marlborough Gallery, New York, NY, USA</p>
          <p>- Documenta 82, Kassel, Germany</p>
          <p>- The U.F.O. Show, Queens Museum, New York, NY, USA</p>
          <p>- Urban Kisses, Institute of Contemporary Art, London, UK</p>
          <p>- Beast: Animal Imagery in Recent Painting, P.S.1, Long Island City, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Drawing Show, The Mudd Club, NewYork, NY, USA</p>
          <p>- New York/New Wave, P.S.1, LongIsland, NY, USA</p>
          <p>- New York/New Wave, Lisson Gallery, London, U.K.</p>
          <p>- New York/New Wave, Brooke Alexander Gallery, New York, NY, USA</p>
          <p>- New York/New Wave, Tony Shafrazi Gallery, New York, NY, USA</p>
          <p>- New York/New Wave, Annina Nosei Gallery, New York, NY, USA</p>
          <p>- Patrick Verelst Gallery, Antwerp, Belgium</p>
          <p>- Bard College, Annendale-on-Hudson, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- Keith Haring and Frank Holiday, Club 57, New York, USA</p>
          <p>- Videotapes: Kenny Scharf and Keith Haring, Club 57, New York, NY, USA</p>
          <p>- Xerox Show, Club 57, New York, NY, USA</p>
          <p>- Club 57 Invitational, New York, NY, USA</p>
          <p>- Times Square Show, New York, NY, USA</p>
          <p>- Open Studio Exhibition, P.S. 122, New York, NY, USA</p>
          <p>- Events: Fashion Moda, The New Museum, New York, NY, USA</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PERMANENT COLLECTIONS</p>
          <br />
          <p>- Louvre, Abu Dhabi, Emirates Arabes Unis</p>
          <p>- Art Institute of Chicago, Illinois, USA</p>
          <p>- The Bass Museum of Art, Miami, Florida, USA</p>
          <p>- Brant Foundation Art Study Center, Greenwich, Connecticut, USA</p>
          <p>- Broad Art Foundation, Los Angeles, California, USA</p>
          <p>- The Bruce Museum, Greenwich, Connecticut, USA</p>
          <p>- Carnegie Museum of Art, Pittsburgh, Pennsylvania, USA</p>
          <p>- Cathedral of St John the Divine, New York City, USA</p>
          <p>- Chase Manhattan Bank Collection, New York City, USA</p>
          <p>- Citygardens, St. Louis, Missouri, USA</p>
          <p>- Columbus Museum of Art, Columbus, Ohio, USA</p>
          <p>- The Denver Art Museum, Colorado, USA</p>
          <p>- Emily Fisher Landau Foundation, New York City, USA</p>
          <p>- Grace Cathedral, San Francisco, California, USA</p>
          <p>- Haggerty Museum, Marquette University, Milwaukee, Wisconsin, USA</p>
          <p>- Los Angeles County Museum of Art, California, USA</p>
          <p>- The Morgan Library & Museum, New York, NY, USA</p>
          <p>- Museum of Contemporary Art, Los Angeles, California, USA</p>
          <p>- Museum of Contemporary Art, San Diego, California, USA</p>
          <p>- The Museum of Modern Art, New York City, USA</p>
          <p>- Norton Museum of Art, West Palm Beach, Florida, USA</p>
          <p>- The John and Mildred Putnam Sculpture Collection: Case Western University, Cleveland, Ohio, USA</p>
          <p>- Rubell Family Collection, Miami, Florida, USA</p>
          <p>- Tampa Museum of Art, Tampa, Florida. USA</p>
          <p>- University of Michigan Museum of Art, Ann Arbor, Michigan, USA</p>
          <p>- The Andy Warhol Museum, Pittsburgh, Pennsylvania, USA</p>
          <p>- The Whitney Museum of American Art, New York City, USA</p>
          <p>- Museum of Contemporary Art, Sydney, Australia</p>
          <p>- Museum of Modern Art, Rio de Janeiro, Brazil</p>
          <p>- Art Gallery of Ontario, Toronto, Ontario, Canada</p>
          <p>- National Gallery of Canada, Ottawa, Ontario, Canada</p>
          <p>- Albertina Collection, Vienna, Austria</p>
          <p>- Museum der Moderne Salzburg, Austria</p>
          <p>- Neue Galerie der Stadt Linz, Austria</p>
          <p>- Palais Rasumofsky Collection, Vienna, Austria</p>
          <p>- Verbeke Foundation, Stekene, Belgium</p>
          <p>- CAPC Musee de l’Art Contemporain, Bordeaux, France</p>
          <p>- Centre Georges Pompidou, Paris, France</p>
          <p>- Eglise St Eustache, Paris, France</p>
          <p>- Musée d’Art Moderne et Contemporain, Nice, France</p>
          <p>- Musée de l’Art Moderne de la Ville de Paris, France</p>
          <p>- Kunsthalle Weishaupt, Ulm, Germany</p>
          <p>- Ludwig Museum, Cologne, Germany</p>
          <p>- Marx Collection, Berlin, Germany</p>
          <p>- Neues Museum Weimar, Germany</p>
          <p>- ZKM/Museum für Neue Kunst, Karlsruhe, Germany</p>
          <p>- Groninger Museum, Groningen, Holland</p>
          <p>- Museum Boijmans van Beuningen, Rotterdam, Holland</p>
          <p>- Stedelijk Museum, Amsterdam, Holland</p>
          <p>- Ludwig Collection, Budapest, Hungary</p>
          <p>- François Pinault Collection, Palazzo Grassi, Venice, Italy</p>
          <p>- Rooseum, Malmo, Sweden</p>
          <p>- Fondation Edelman, Lausanne, Switzerland</p>
          <p>- HausBill, Zumikon, Switzerland</p>
          <p>- Museum of Modern and Contemporary Art, Geneva, Switzerland</p>
          <p>- The Israel Museum, Jerusalem, Israel</p>
          <p>- Benesse House, Naoshima, Japan</p>
          <p>- The Museum of Art, Kochi, Japan</p>
          <p>- Museum of Contemporary Art, Hiroshima, Japan</p>
          <p>- Nakamura Keith Haring Collection, Kobuchizawa, Japan</p>
          <p id="end">- Fluctuart, Paris, France.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default KeithHaring;